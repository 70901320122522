import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect : "/allProject",
  },
  {
    path: "/allProject",
    name: "allProject",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/allProjectList.vue"),
    meta: {
      title: "项目列表",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/wechatLogin",
    name: "wechatLogin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wechatLogin.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/bind",
    name: "Bind",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bind.vue"),
    meta: {
      title: "绑定手机号",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/agreement.vue"),
    meta: {
      title: "服务协议",
    },
  },
  {
    path: "/myDonate",
    name: "myDonate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/myDonateList.vue"),
    meta: {
      title: "我发起的一起捐",
    },
  },
  {
    path: "/myProject",
    name: "myProject",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/myProjectList.vue"),
    meta: {
      title: "项目列表",
    },
  },
  {
    path: "/project/projectDetail",
    name: "projectDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/projectDetail.vue"),
    meta: {
      title: "项目详情",
    },
  },
  {
    path: "/project/moreDonateList",
    name: "moreDonateList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/moreDonateList.vue"),
    meta: {
      title: "捐赠列表",
    },
  },
  {
    path: "/project/offLine",
    name: "offLineList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/offLineList.vue"),
    meta: {
      title: "捐赠列表",
    },
  },
  {
    path: "/project/donateTogether",
    name: "donateTogether",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/donateTogether.vue"),
    meta: {
      title: "发起一起捐",
    },
  },
  {
    path: "/project/ownDonate",
    name: "ownDonate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ownDonate.vue"),
    meta: {
      title: "捐赠",
    },
  },
  {
    path: "/project/certificate",
    name: "certificate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/certificate.vue"),
    meta: {
      title: "我的捐赠证书",
    },
  },
  {
    path: "/project/message",
    name: "message",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/messageList.vue"),
    meta: {
      title: "留言",
    },
  },
  {
    path: "/record",
    name: "record",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/recordList.vue"),
    meta: {
      title: "我的捐赠记录",
    },
  },
  {
    path: "/project/share",
    name: "share",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/share.vue"),
    meta: {
      title: "邀你一起捐",
    },
  },
  {
    path: "/project/together",
    name: "together",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/togetherList.vue"),
    meta: {
      title: "一起捐",
    },
  },
  {
    path: "/alipay",
    name: "alipay",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/alipay.vue"),
    meta: {
      title: "支付宝支付",
    },
  },
  {
    path: "/alipayWechat",
    name: "alipayWechat",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/alipayWechat.vue"),
    meta: {
      title: "支付宝支付",
    },
  },
  {
    path: "/alipay/detail",
    name: "detail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/detail.vue"),
    meta: {
      title: "支付成功",
    },
  },
  {
    path: "/wechatPay",
    name: "wechatPay",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wechatPay.vue"),
    meta: {
      title: "微信支付",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
