<template>
  <div class="commonBox mineBox">
    <!-- 个人信息 -->
    <div class="mineTop">
      <div class="photoBox">
        <img :src="info.avatar" class="photoImg" v-if="info.avatar" />
        <img src='../assets/avatar.png' v-else class="photoImg" />
      </div>
      <div class="mineDetail">
        <div class="nameBox">
          <img src="../assets/mineIcon.png" class="mineIcon" />
          <div class="nameText">{{ info.truename || "手机用户" }}</div>
        </div>
        <div class="mobileBox">
          <img src="../assets/mobileIcon.png" class="mobileIcon" />
          <div class="mobileNum">{{ info.mobile }}</div>
        </div>
      </div>
    </div>
    <!-- 活动项目 -->
    <div class="listBox">
      <div class="listItem" @click="goMyProject">
        <div class="itemLeft">
          <img src="../assets/list1.png" class="listIcon" />
          <div class="itemTitle">我的项目</div>
        </div>
        <van-icon name="arrow" class="iconFont" />
      </div>
      <div class="listItem" @click="goMyDonate">
        <div class="itemLeft">
          <img src="../assets/list2.png" class="listIcon" />
          <div class="itemTitle">我发起的一起捐赠</div>
        </div>
        <van-icon name="arrow" class="iconFont" />
      </div>
      <div class="listItem" @click="goRecord">
        <div class="itemLeft">
          <img src="../assets/list3.png" class="listIcon" />
          <div class="itemTitle">我的捐赠记录</div>
        </div>
        <van-icon name="arrow" class="iconFont" />
      </div>
    </div>
  </div>
</template>

<script>
import { getMine } from "@/api/index.js";

export default {
  name: "Home",
  data() {
    return {
      info: {},
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    //去往我的项目列表
    goMyProject() {
      this.$router.push({ path: "/myProject" });
    },
    //去往我发起的一起捐赠列表
    goMyDonate() {
      this.$router.push({ path: "/myDonate" });
    }, //去往我的记录
    goRecord() {
      this.$router.push({ path: "/record" });
    },
    //获取个人信息
    async getInfo() {
      const res = await getMine();
      if(res){
        if (res.status == 200) {
          console.log(res.data, "info");
          this.info = res.data;
        } else {
          this.$toast(res.err_msg);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mineBox {
  padding: 165rem 30rem;
}
.mineTop {
  display: flex;
  align-items: center;
  padding-left: 35rem;
  margin-bottom: 45rem;
}
.photoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130rem;
  height: 130rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 37rem;
}
.photoImg {
  width: 130rem;
}
.nameBox {
  display: flex;
  align-items: center;
  padding-left: 15rem;
  margin-bottom: 20rem;
}
.mineIcon {
  width: 25rem;
  height: 29rem;
  margin-right: 20rem;
}
.nameText {
  font-size: 36rem;
  font-weight: 700;
}
.mobileBox {
  display: flex;
  align-items: center;
  height: 48rem;
  padding: 0 25rem;
  background-color: #b8211a;
  border-radius: 24rem;
}
.mobileIcon {
  width: 18rem;
  height: 21rem;
  margin-right: 20rem;
}
.mobileNum {
  font-size: 24rem;
  color: #fff;
}
.listBox {
  width: 690rem;
  border-radius: 8rem;
  background-color: #fff;
  padding: 30rem 50rem;
  min-height: 710rem;
}
.listItem {
  padding: 30rem 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e8e8;
}
.itemLeft {
  display: flex;
  align-items: center;
}
.listIcon {
  width: 55rem;
  height: 55rem;
  margin-right: 25rem;
}
.itemTitle {
  font-size: 26rem;
  color: #333333;
}
.iconFont {
  font-size: 32rem;
  color: #333333;
}
</style>
